import React from 'react';

export const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

const MobileWarning = () => {
  if (!isMobileDevice()) {
    return null;
  }

  return (
    <div style={{ padding: '10px', backgroundColor: 'red', color: 'white', textAlign: 'center' }}>
      The App isn't optimized for mobile. Please use a desktop for the best experience.
    </div>
  );
};

export default MobileWarning;