import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import RegisterOrLogin from './components/RegisterorLogin/RegisterOrLogin';
import TermsAndConditions from './components/TermsandConditions/TermsandConditions';
import AuthenticatedApp from './AuthenticatedApp';
import { logPageView } from './gtag';
import MobileWarning from './components/MobileWarning/MobileWarning';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <Routes>
      <Route path="/login" element={<RegisterOrLogin />} />
      <Route path="/*" element={<AuthenticatedApp />} />
      <Route path="/termsandconditions" element={<TermsAndConditions />} />
      <Route path="*" element={<Navigate to="/termsandconditions" />} />
    </Routes>
  );
};

const AppWrapper = () => (
  <Router>
    <MobileWarning />
    <App />
  </Router>
);

export default AppWrapper;
