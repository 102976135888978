import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FeatureRequestPopup.css';
const FeatureRequestPopup = ({ url, onClose, userID }) => {
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('Carbon Modelling');
    const [description, setDescription] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async () => {
        if (!title || !description) {
            setErrorMessage('Please fill out all fields');
            return;
        }

        try {
            await axios.post(`${url}/feature-request`, {
                title,
                category,
                description,
                user_id: userID,
            });
            onClose();
        } catch (error) {
            console.error('Error submitting feature request', error);
            setErrorMessage('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="overlay">
            <div className="popup">
            <span className="form-row" onClick={onClose}>
            </span>
                <h2>Feature Request</h2>
                <div className="form-row">
                    <div className="input-data">
                        <p>Title</p>
                        <label htmlFor="title"></label>
                        <input
                            type="text"
                            id="title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                <p>Category</p>
                <label htmlFor="category"></label>
                <select
                    id="category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                >
                    <option value="Carbon Modelling">Carbon Modelling</option>
                    <option value="Project Management">Economic Feasibility</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            </div>
                <div className="form-row textarea">
                <p>Description</p>
                <label htmlFor="description"></label>
                <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>
                <div className="form-row submit-cancel">
                    <div className="input-data cancel-btn">
                        <button type="button" onClick={onClose}>Cancel</button>
                    </div>
                    <div className="input-data submit-btn">
                        <button type="submit" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
        </div>
    );
}


export default FeatureRequestPopup;