import React from 'react';
import './PDFViewer.css'; // Create and import a CSS file for styling

const PDFViewer = ({ documentLink, onClose }) => {
  return (
    <div className="overlay">
      <div className="popup">
        <button className="close-btn" onClick={onClose}>Close</button>
        <iframe
          src={documentLink}
          className="pdf-iframe"
          title="PDF Viewer"
        />
      </div>
    </div>
  );
};

export default PDFViewer;
