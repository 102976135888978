import React, { useState } from 'react';
import axios from 'axios';
import './RegisterOrLogin.css';
import { useNavigate } from 'react-router-dom';

function RegisterOrLogin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [isRegistered, setIsRegistered] = useState(true); // Default to login view
  const [acceptedTC, setAcceptedTC] = useState(false); // State for the terms and conditions checkbox
  // const allowRegister = process.env.REACT_APP_ALLOW_REGISTER;
    const allowRegister = true;
  const url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isRegistered && !acceptedTC) {
      alert('You must accept the Terms and Conditions to register.');
      return;
    }

    if (isRegistered) {
      try {
        const response = await axios.post(`${url}/login/`, { email, password });
        localStorage.setItem('token', response.data.access_token);
        navigate('/dashboard');
      } catch (error) {
        console.error('Error logging in', error);
      }
    } else {
      try {
        const response = await axios.post(`${url}/register/`, { email, password, first_name, last_name, company });
        navigate('/login')
        alert('Please verify your email address using the link sent to complete registration.');
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setIsRegistered(true);
          navigate('/dashboard');
        } else {
          console.error('Error registering user', error);
        }
      }
    }
  };

  const handleTC = () => {
    window.open('/termsandconditions', '_blank');
  };

  const toggleForm = () => {
    setIsRegistered(!isRegistered);
  };

  return (
    <div className="register-or-login-container">
      <h2>{isRegistered ? 'Login' : 'Register'}</h2>
      <form className="register-or-login-form" onSubmit={handleSubmit}>
        {!isRegistered && (
          <>
            <label>
              First Name:
              <input type="text" value={first_name} onChange={e => setFirstName(e.target.value)} required />
            </label>
            <label>
              Last Name:
              <input type="text" value={last_name} onChange={e => setLastName(e.target.value)} required />
            </label>
          </>
        )}
        <label>
          Email:
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />
        </label>
        <label>
          Password:
          <input type="password" value={password} onChange={e => setPassword(e.target.value)} required />
        </label>
        {!isRegistered && (
          <label>
            Company:
            <input type="text" value={company} onChange={e => setCompany(e.target.value)} required />
          </label>
        )}
        {!isRegistered && (
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={acceptedTC}
              onChange={e => setAcceptedTC(e.target.checked)}
            />
            <span onClick={handleTC} style={{ color: 'blue', cursor: 'pointer' }}>By clicking here, I state that I have read and understood the terms and conditions..</span>
          </label>
        )}
        <button type="submit">{isRegistered ? 'Login' : 'Register'}</button>
      </form>
      {allowRegister && (
        <p className="toggle-form-text" onClick={toggleForm}>
          {isRegistered ? 'New user? Register here.' : 'Already registered? Login here.'}
        </p>
      )}
    </div>
  );
}

export default RegisterOrLogin;
