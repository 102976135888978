import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeApp = ({ url, project_id, parent_id, user_id, orderType, orderPrice }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const navigate = useNavigate();

  orderPrice = orderPrice * 100;

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await fetch(`${url}/create-checkout-session/`, {
          method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ orderPrice, orderType })
        });


        const data = await response.json();
        console.log('Data from server:', data);
        setClientSecret(data.clientSecret);
        setSessionId(data.sessionId);
      } catch (error) {
        console.error("Error fetching client secret:", error);
      }
    };

    fetchClientSecret();
  }, [url]);

  const handlePaymentAttempt = async () => {
    console.log('Payment attempted!');

    if (!sessionId) {
      console.log('No session ID available');
      return;
    }

    try {
      const response = await fetch(`${url}/session-status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({sessionId, project_id, parent_id, user_id, orderType}),
      });
      const data = await response.json();
      window.location.reload();
      console.log('Session status:', data.status, 'Payment status:', data.payment_status);
    } catch (error) {
      console.error("Error checking session status:", error);
    }
  };

  const options = { clientSecret: clientSecret };

  return (
    <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{
        ...options,
        onComplete: handlePaymentAttempt
      }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default StripeApp;
