import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AccountPage.css';
import { useNavigate } from 'react-router-dom';
import logo from '../logo.png';

function AccountPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(`${url}/user`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        const { email, first_name, last_name, company } = response.data;
        setEmail(email);
        setFirstName(first_name);
        setLastName(last_name);
        setCompany(company);
      } catch (error) {
        console.error('Error fetching user details', error);
      }
    };

    fetchUserDetails();
  }, [url]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${url}/user`, { email, password, first_name, last_name, company }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      alert('Account details updated successfully');
      /// when someone clicks okay, it should navigate to the dashboard
      navigate('/dashboard');

    } catch (error) {
      console.error('Error updating account details', error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  }

  const handleDashboard = () => {
    navigate('/dashboard');
  }

  return (
      <div className="account-page">
        <div className="banner">
          <div className={"banner-left"}>
            <img src={logo} alt="Artio" className="logo"/>
            <button className="banner-dashboard" onClick={handleDashboard}>Dashboard</button>
          </div>
          <div className="banner-center">
            <div className="banner-title">My Account</div>
          </div>
          <div className="banner-right">
            <button className="banner-name" onClick={() => window.location.reload()}>{localStorage.getItem('fullName')}</button>
            <button className="banner-logout" onClick={handleLogout}>Logout</button>
          </div>
        </div>
        <div className="account-container">
          <h2>Account Details</h2>
          <form className="account-form" onSubmit={handleSubmit}>
            <label>
              First Name:
              <input type="text" value={first_name} onChange={e => setFirstName(e.target.value)} required/>
            </label>
            <label>
              Last Name:
              <input type="text" value={last_name} onChange={e => setLastName(e.target.value)} required/>
            </label>
            <label>
              Email:
              <input type="email" value={email} onChange={e => setEmail(e.target.value)} required/>
            </label>
            <label>
              Password:
              <div className="password-container">
                <input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <button
                    type="button"
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>
            </label>
            <label>
              Company:
              <input type="text" value={company} onChange={e => setCompany(e.target.value)} required/>
            </label>
            <button type="submit">Update Details</button>
          </form>
        </div>
      </div>
  );
}

export default AccountPage;
