import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import logo from "../logo.png";
import ProjectCard from "../ProjectCard/ProjectCard";
import PDFViewer from "../PDFViewer/PDFViewer";
import ProjectRerunPopup from "../ProjectRerunPopup/ProjectRerunPopup";
import './LoadingSpinner.css'; // Import CSS for loading spinner

const ProjectRuns = () => {
  const { projectId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { url } = location.state || {};
  const [runs, setRuns] = useState([]);
  const [documentLink, setDocumentLink] = useState('');
  const [showPDFViewer, setShowPDFViewer] = useState(false); // State to control PDF viewer modal
  const [loading, setLoading] = useState(false); // State to control loading indicator
  const token = localStorage.getItem('token');
  const [showForm, setShowForm] = useState(false);
  const [project, setProject] = useState(null); // Initialize as null
  const [runCount, setRunCount] = useState(0); // Initial value as 0 instead of empty array
  const fullName = localStorage.getItem('fullName');
  const [projectName, setProjectName] = useState('');

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const backToDashboard = () => {
    navigate('/dashboard');
  };

  const calculateStatus = (project, analysis) => {
    if (analysis) {
      return "Complete";
    } else {
      const projectCreated = new Date(project.created_at);

      const timeDifference = new Date() - projectCreated;
      const timeDifferenceInMinutes = timeDifference / (1000 * 60);

      if (timeDifferenceInMinutes < 10) { // 15 minutes in milliseconds
        return "Running";
      } else {
        // log an error
        return "Failed";
      }
    }
  };

  const fetchRuns = async () => {
    try {
      const response = await axios.get(`${url}/project_runs/${projectId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = response.data;

      const projectsWithStatus = data.map(project => {
        const status = calculateStatus(project, project.analysis);
        return { ...project, status };
      });

      setRuns(projectsWithStatus);
      setProject(projectsWithStatus[0]); // Set the project data to the first run
      setRunCount(projectsWithStatus.length);
      setProjectName(projectsWithStatus[0].projectName)

    } catch (error) {
      console.error('Error fetching project runs', error);
      if (error.response && error.response.status === 401) {
        handleLogout(); // Log out if unauthorized
      }
    }
  };

  useEffect(() => {
    fetchRuns();
  }, [projectId, url]);

  const handleClose = () => {
    fetchRuns();
    setShowForm(false);
  };

  const handleProjectClick = async (projectId) => {
    try {
      setLoading(true); // Set loading to true when fetch starts
      console.log(`Fetching document link for project ID: ${projectId}`);
      const response = await axios.get(`${url}/project/document_link`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          project_id: projectId
        },
        responseType: 'blob'  // Ensures the response is handled as a binary large object
      });
      console.log("Document link fetched successfully");

      const blob = response.data;
      if (blob.size < 100) {
        alert('No document found for this project.');
        setLoading(false); // Set loading to false if no document is found
      } else {
        const fileURL = URL.createObjectURL(blob);
        setDocumentLink(fileURL);
        setShowPDFViewer(true); // Show the PDF viewer modal
      }
    } catch (error) {
      console.error('Error fetching document link', error);
      alert('No document found for this project.')
    } finally {
      setLoading(false); // Set loading to false when fetch completes
    }
  };

  const handleDashboard = () => {
    navigate('/dashboard');
  }

  const handleAccount = () => {
    navigate('/account');
  }

  return (
      <div className="user-page">
        <div className="banner">
          <div className={"banner-left"}>
            <img src={logo} alt="Artio" className="logo"/>
            <button className="banner-dashboard" onClick={handleDashboard}>Dashboard</button>
          </div>
          <div className="banner-center">
            <div className="banner-title">{projectName}</div>
          </div>
          <div className="banner-right">
            <button className="banner-name"
                    onClick={handleAccount}>{fullName}</button>
            <button className="banner-logout" onClick={handleLogout}>Logout</button>
          </div>
        </div>
        <div className="section-header">
          <button className="back-btn" onClick={backToDashboard}>Back</button>
          <button
              className="new-order-btn"
              onClick={() => {
                if (runCount < 3) {
                  setShowForm(true);
                } else {
                  alert("Maximum number of project reruns has been reached. Please order a new project");
                }
              }}
          >New Run
          </button>
          {showForm && project && (
              <ProjectRerunPopup
                  url={url}
                  onClose={handleClose}
                  projectData={project}
              />
          )}
        </div>
        <div className="project-cards">
          {runs.map((run) => (
              <ProjectCard
                  key={run.id}
                  project={run}
                  onClick={() => handleProjectClick(run.id)}  // Use run.id to pass the project ID
              />
          ))}
        </div>
        {loading && (
            <div className="loading-overlay">
              <div className="loading-spinner"></div>
              <p>Document is being loaded, please wait...</p>
            </div>
        )}
        {showPDFViewer && (
            <PDFViewer
                documentLink={documentLink}
                onClose={() => setShowPDFViewer(false)}
            />
        )}
        <div className="footer">
          <a href="mailto:app@artiocarbon.com?subject=Information Request&body=Hi Artio."
             target="_blank" rel="noopener noreferrer" style={{color: 'white'}}>
            Contact us via email
          </a>
        </div>
      </div>
  );
};

export default ProjectRuns;
