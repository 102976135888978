import React, { useState, useEffect, useRef } from 'react';

const MultiSelectForm = ({ species, formData, setFormData }) => {
  const [speciesWeights, setSpeciesWeights] = useState([{ species: '', weight: 100 }]);
  const [currentCountry, setCurrentCountry] = useState(formData.projectCountry);
  const isFirstRun = useRef(true);

  const handleSpeciesChange = (index, event) => {
    const newSpeciesWeights = [...speciesWeights];
    newSpeciesWeights[index].species = event.target.value;
    setSpeciesWeights(newSpeciesWeights);
  };

  const handleWeightChange = (index, event) => {
    const newSpeciesWeights = [...speciesWeights];
    newSpeciesWeights[index].weight = parseInt(event.target.value) || 0; // Ensure weight is a number
    setSpeciesWeights(newSpeciesWeights);
  };

  const addRow = () => {
    if (speciesWeights.length < 10) {
      const newSpeciesWeights = [...speciesWeights, { species: species[formData.projectCountry][0], weight: 0 }];
      setSpeciesWeights(newSpeciesWeights);
      distributeWeightsEvenly(newSpeciesWeights);
    }
  };

  const removeRow = (index) => {
    const newSpeciesWeights = speciesWeights.filter((_, i) => i !== index);
    distributeWeightsEvenly(newSpeciesWeights);
    setSpeciesWeights(newSpeciesWeights);
  };

  const distributeWeightsEvenly = (weightsArray) => {
    const weightPerSpecies = Math.floor(100 / weightsArray.length);
    const remainder = 100 % weightsArray.length;

    const newWeightsArray = weightsArray.map((item, index) => {
      return {
        ...item,
        weight: weightPerSpecies + (index < remainder ? 1 : 0),
      };
    });

    setSpeciesWeights(newWeightsArray);
  };

  useEffect(() => {
    if (isFirstRun.current) {
      const initialSpecies = species[formData.projectCountry] || [];
      const initialSpeciesWeights = Object.keys(formData.projectSpecies).length > 0
        ? Object.keys(formData.projectSpecies).map(specie => ({ species: specie, weight: formData.projectSpecies[specie] }))
        : initialSpecies.length > 0 ? [{ species: initialSpecies[0], weight: 100 }] : [{ species: '', weight: 100 }];

      setSpeciesWeights(initialSpeciesWeights);
      isFirstRun.current = false;
    } else if (currentCountry !== formData.projectCountry) {
      const initialSpecies = species[formData.projectCountry] || [];
      const initialSpeciesWeights = initialSpecies.length > 0 ? [{ species: initialSpecies[0], weight: 100 }] : [{ species: '', weight: 100 }];
      setSpeciesWeights(initialSpeciesWeights);
      setCurrentCountry(formData.projectCountry);
    }
  }, [formData.projectCountry, species, formData.projectSpecies, currentCountry]);

  useEffect(() => {
    const speciesWeightsDict = speciesWeights.reduce((acc, sw) => {
      if (sw.species) acc[sw.species] = sw.weight;
      return acc;
    }, {});

    if (JSON.stringify(speciesWeightsDict) !== JSON.stringify(formData.projectSpecies)) {
      setFormData((prevData) => ({
        ...prevData,
        projectSpecies: speciesWeightsDict,
      }));
    }
  }, [speciesWeights, setFormData, formData.projectSpecies]);

  const totalWeight = speciesWeights.reduce((total, sw) => total + sw.weight, 0);

  return (
    <div className="multi-select-form">
      {speciesWeights.map((sw, index) => (
        <div className="form-row" key={index}>
          <div className="input-data">
            <select
              name={`species-${index}`}
              value={sw.species}
              onChange={(event) => handleSpeciesChange(index, event)}
              required
            >
              {(species[formData.projectCountry] || []).map((specie) => (
                <option key={specie} value={specie}>
                  {specie}
                </option>
              ))}
            </select>
            <div className="underline"></div>
            <label htmlFor={`species-${index}`}>Species</label>
          </div>
          <div className="icon-column">
            {index === speciesWeights.length - 1 && speciesWeights.length < 10 ? (
              <button type="button" className="add-remove-btn" onClick={addRow}>+</button>
            ) : (
              <button type="button" className="add-remove-btn remove" onClick={() => removeRow(index)}>-</button>
            )}
          </div>
          <div className="input-data">
            <input
                type="number"
                name={`weight-${index}`}
                value={sw.weight}
                onChange={(event) => handleWeightChange(index, event)}
                required
                min="0"
                max="100"
            />
            <div className="underline"></div>
            <label htmlFor={`weight-${index}`}>Weight</label>
          </div>
        </div>
      ))}
      {totalWeight !== 100 &&
          <p style={{color: 'red', fontSize: '12px'}}>Total weight must add up to 100. Current total: {totalWeight}</p>}
    </div>
  );
};

export default MultiSelectForm;
