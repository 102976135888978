import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Dashboard from './components/Dashboard/Dashboard';
import ProjectRuns from './components/ProjectRuns/ProjectRuns';
import AccountPage from './components/AccountPage/AccountPage';
import { logPageView } from './gtag';

const AuthenticatedApp = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  useEffect(() => {
    const checkTokenValidity = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/validate-token`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          if (response.status === 200) {
            setIsAuthenticated(true);
          } else {
            setIsAuthenticated(false);
            localStorage.removeItem('token');
          }
        } catch (error) {
          console.error('Token validation failed', error);
          setIsAuthenticated(false);
          localStorage.removeItem('token');
        }
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    checkTokenValidity();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Show a loading spinner or message
  }

  return (
    <Routes>
      <Route path="/" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
      <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
      <Route path="/projects/:projectId/runs" element={isAuthenticated ? <ProjectRuns /> : <Navigate to="/login" />} />
      <Route path="*" element={<Navigate to="/dashboard" />} />
      <Route path="/account" element={isAuthenticated ? <AccountPage /> : <Navigate to="/login" />} />
    </Routes>
  );
};

export default AuthenticatedApp;
