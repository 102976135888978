import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './ProjectRerunPopup.css';
import MultiSelectForm from "../NewOrder/MultiSpeciesPopup";

const ProjectRerunPopup = ({url, onClose, projectData}) => {
  const [formData, setFormData] = useState({
    projectType: projectData.projectType,
    projectName: projectData.projectName,
    projectDescription: projectData.projectDescription,
    user_id: projectData.user_id,
    projectSpecies: projectData.projectSpecies,
    projectCountry: projectData.projectCountry,
    projectSize: projectData.projectSize,
    landAcquisitionType: projectData.landAcquisitionType,
    landAcquisitionCost: projectData.landAcquisitionCost,
    landAcquisitionCostFrequency: projectData.landAcquisitionCostFrequency,
    registry: projectData.registry,
    plantingDensity: projectData.plantingDensity,
    parent_id: projectData.parent_id,
    creditPrice: projectData.creditPrice,
    creditPriceGrowth: projectData.creditPriceGrowth,
    orderType: projectData.orderType,
  });

  const orderType = projectData.orderType;
  const [species, setSpecies] = useState({});
  const [registries, setRegistries] = useState([]);
  const [landAcquisitionTypes, setLandAcquisitionTypes] = useState([]);
  const [landAcquisitionCostFrequencies, setLandAcquisitionCostFrequencies] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const token = localStorage.getItem('token');
  const [plantingDensity, setPlantingDensity] = useState(1000);


  useEffect(() => {
  const fetchDropdownOptions = async () => {
    try {
      const [
        speciesResponse,
        registriesResponse,
        landAcquisitionTypesResponse,
        landAcquisitionCostFrequenciesResponse,
        orderTypeResponse
      ] = await Promise.all([
        axios.get(`${url}/species`, {headers: {Authorization: `Bearer ${token}`}}),
        axios.get(`${url}/registries`, {headers: {Authorization: `Bearer ${token}`}}),
        axios.get(`${url}/land-acquisition-types`, {headers: {Authorization: `Bearer ${token}`}}),
        axios.get(`${url}/land-acquisition-cost-frequencies`, {headers: {Authorization: `Bearer ${token}`}}),
      ]);
      setSpecies(speciesResponse.data);
      setRegistries(registriesResponse.data);
      setLandAcquisitionTypes(landAcquisitionTypesResponse.data);
      setLandAcquisitionCostFrequencies(landAcquisitionCostFrequenciesResponse.data);
    } catch (error) {
      console.error('Error fetching dropdown options', error);
    }
  };

    fetchDropdownOptions();
  }, [url, token]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    // if country is selected, filter the species based on the country
    if (name === 'projectCountry') {
      const selectedCountry = value;
      const filteredSpecies = species[selectedCountry] || [];
      setFormData({
        ...formData,
        projectCountry: selectedCountry,
        projectSpecies: filteredSpecies.length > 0 ? filteredSpecies[0] : '' // Reset the species field to the first available species
      });
    }
  };

  const handleSubmit = (e) => {
      e.preventDefault(); // Prevent default form submission behavior
      delete formData.project_id;

      // Initiate the API call
      axios.post(`${url}/project/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Unexpected response status: ' + response.status);
        }
      })
      .catch(error => {
        setErrorMessage('Error while submitting, please double check your inputs or drop an email to info@artiocarbon.com.');
        console.error('Error creating project:', error);
        alert("Your previous project was not successfully rerun. Please try again or email info@artiocarbon.com.")
      });

      // Close the form immediately after initiating the API call
      window.location.reload();
  };


  return (
    <div className="overlay">
      <div className="popup">
        <h2>Rerun Analysis</h2>
        <h3>Rerun Type: {orderType}</h3>
        <form className="order-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <div className="underline">Project Type: {formData.projectType}</div>
            <div className="underline">Country: {formData.projectCountry}</div>
          </div>
          <div className="form-row">
            <div className="input-data">
              <input
                  type="text"
                  name="projectName"
                  value={formData.projectName}
                  onChange={handleFormChange}
                  required
              />
              <label htmlFor="projectName">Project Name</label>
            </div>
            <div className="input-data">
              <input
                  type="number"
                  name="projectSize"
                  value={formData.projectSize}
                  onChange={handleFormChange}
                  required
              />
              <label htmlFor="projectSize">Size</label>
            </div>
          </div>
          <MultiSelectForm
              species={species}
              formData={formData}
              setFormData={setFormData}
          />
          {orderType === 'Economic Feasibility' && (
              <>
                <div className="form-row">
                  <div className="input-data">
                    <select
                        name="registry"
                        value={formData.registry}
                        onChange={handleFormChange}
                        required
                    >
                      {registries.map((registry) => (
                          <option key={registry} value={registry}>
                            {registry}
                          </option>
                      ))}
                    </select>
                    <label htmlFor="registry">Registry</label>
                  </div>
                  <div className="input-data">
                    <input
                        type="number"
                        name="landAcquisitionCost"
                        value={formData.landAcquisitionCost}
                        onChange={handleFormChange}
                    />
                    <label htmlFor="landAcquisitionCost">Land Acquisition Cost USD (Optional)</label>
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                    <select
                        name="landAcquisitionType"
                        value={formData.landAcquisitionType}
                        onChange={handleFormChange}
                        required
                    >
                      {landAcquisitionTypes.map((type) => (
                          <option key={type} value={type}>
                            {type}
                          </option>
                      ))}
                    </select>
                    <label htmlFor="landAcquisitionType">Land Acquisition Type</label>
                  </div>
                  <div className="input-data">
                    <select
                        name="landAcquisitionCostFrequency"
                        value={formData.landAcquisitionCostFrequency}
                        onChange={handleFormChange}
                        required
                    >
                      {landAcquisitionCostFrequencies.map((frequency) => (
                          <option key={frequency} value={frequency}>
                            {frequency}
                          </option>
                      ))}
                    </select>
                    <label htmlFor="landAcquisitionCostFrequency">Land Acquisition Cost Frequency</label>
                  </div>
                </div>
                <div className="form-row">
                  <div className="input-data">
                    <input
                        type="number"
                        name="creditPrice"
                        value={formData.creditPrice}
                        onChange={handleFormChange}
                    ></input>
                    <div className="underline"></div>
                    <label htmlFor="creditPrice">Starting Credit Price USD (Optional)</label>
                  </div>
                  <div className="input-data">
                    <input
                        type="number"
                        name="creditPriceGrowth"
                        value={formData.creditPriceGrowth}
                        onChange={handleFormChange}
                    />
                    <div className="underline"></div>
                    <label htmlFor="creditPriceGrowth">Price Growth % (1-100) (Optional)</label>
                  </div>
                </div>
              </>
          )}
          <div className="form-row textarea">
            <label htmlFor="description">Project Description</label>
            <textarea
                name="projectDescription"
                rows="8"
                value={formData.projectDescription}
                onChange={handleFormChange}
            ></textarea>
            <div className="underline"></div>
          </div>
          <div className="form-row submit-cancel">
            <div className="input-data cancel-btn">
              <button type="button" onClick={onClose}>Cancel</button>
            </div>
            <div className="input-data submit-btn">
              <button type="submit">Proceed</button>
            </div>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </form>
      </div>
    </div>
  );


};


export default ProjectRerunPopup;
