import React from 'react';
import ReactDOM from 'react-dom/client';
import AppWrapper from './App';
import { initGA } from './gtag';
import Hotjar from '@hotjar/browser';

const root = ReactDOM.createRoot(document.getElementById('root'));
const siteId = 5062330;
const hotjarVersion = 6;

initGA(); // Initialize Google Analytics

Hotjar.init(siteId, hotjarVersion);
root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>
);
