import { useNavigate } from 'react-router-dom';
import React from 'react';
import backgroundImage from '../background.jpg'; // replace 'path_to_your_image' with the actual path to your image

const ProjectCard = ({ project, cardType, onClick }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (onClick) {
            onClick(project.id);
        } else {
            navigate(`/projects/${project.id}/runs`);
        }
    };

    const formatCarbonCredits = (value) => {
  return `${Math.round(value / 1_000)}k`;
};

    return (
        <div className="project-card" onClick={handleClick}>
            <h3>{project.projectName ? project.projectName : "No Name"}</h3>
            <img className="project-image" src={backgroundImage} alt="Project background"/>
            {cardType === "parent" ? (
                <>
                    <p>Total Runs: {project.totalRuns}</p>
                    <p>Runs Left: {project.runsLeft}</p>
                    <p>Project Type: {project.projectType}</p>
                    <p>Country: {project.projectCountry}</p>
                </>
            ) : (
                <>
                    <p>Size: {project.projectSize}</p>
                    <p>Country: {project.projectCountry}</p>
                    <p>
                        Carbon
                        Credits: {project.analysis ? `${formatCarbonCredits(project.analysis.carbon_low)} - ${formatCarbonCredits(project.analysis.carbon_high)}` : 'N/A'}
                    </p>
                    {project.orderType === 'Economic Feasibility' && (
                        <p>
                            Potential
                            IRR: {project.analysis ? `${project.analysis.irr_low}% - ${project.analysis.irr_high}%` : 'N/A'}
                        </p>
                    )}
                    <p>Status: {project.analysis && project.analysis.carbon_low === 0 && project.analysis.carbon_high === 0 && project.analysis.irr_low === 0 && project.analysis.irr_high === 0 ? 'Failed' : project.status}</p>
                </>
            )}
        </div>
    );
};

export default ProjectCard;
