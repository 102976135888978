import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NewOrderPopup.css';
import StripeApp from "../EmbeddedCheckoutProvider/EmbeddedCheckoutProvider";
import { useNavigate } from "react-router-dom";
import FeasibilityImage from './Feasibility Image.png';
import MultiSelectForm from './MultiSpeciesPopup';
import {logEvent} from "../../gtag";

const NewOrderPopup = ({ url, onClose, user }) => {
  const [formData, setFormData] = useState({
    projectType: 'ARR',
    projectName: '',
    projectDescription: '',
    user_id: '',
    projectSpecies: {'Bignoniaceae Markhamia': 100},
    projectCountry: 'Kenya',
    projectSize: 100,
    landAcquisitionType: 'Lease',
    landAcquisitionCost: undefined,
    landAcquisitionCostFrequency: 'One-off',
    registry: 'Verra',
    plantingDensity: 1000,
    creditPrice: undefined,
    creditPriceGrowth: undefined,
    orderType: undefined,
  });

  const [orderType, setOrderType] = useState('');
  const [projectTypes, setProjectTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [species, setSpecies] = useState({});
  const [registries, setRegistries] = useState([]);
  const [landAcquisitionTypes, setLandAcquisitionTypes] = useState([]);
  const [landAcquisitionCostFrequencies, setLandAcquisitionCostFrequencies] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const token = localStorage.getItem('token');
  const [showStripeApp, setShowStripeApp] = useState(false);
  const [orderPrice, setPrice] = useState();
  const [plantingDensity, setPlantingDensity] = useState(1000);
  const [projectID, setProjectID] = useState([]);
  const [userID, setUserID] = useState(user.user_id);
  const superUser = user.is_superuser;
  const subscription = user.is_subscription;
  const trial = user.is_trial;
  const monthly_orders = user.monthly_runs;
  const [parentID, setParentID] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const [
          projectTypesResponse,
          countriesResponse,
          speciesResponse,
          registriesResponse,
          landAcquisitionTypesResponse,
          landAcquisitionCostFrequenciesResponse
        ] = await Promise.all([
          axios.get(`${url}/project-types`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`${url}/countries`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`${url}/species`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`${url}/registries`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`${url}/land-acquisition-types`, { headers: { Authorization: `Bearer ${token}` } }),
          axios.get(`${url}/land-acquisition-cost-frequencies`, { headers: { Authorization: `Bearer ${token}` } })
        ]);
        setProjectTypes(projectTypesResponse.data);
        setCountries(countriesResponse.data);
        setSpecies(speciesResponse.data);
        setRegistries(registriesResponse.data);
        setLandAcquisitionTypes(landAcquisitionTypesResponse.data);
        setLandAcquisitionCostFrequencies(landAcquisitionCostFrequenciesResponse.data);
      } catch (error) {
        console.error('Error fetching dropdown options', error);
      }
    };

    fetchDropdownOptions();
  }, [url, token]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    if (name === 'projectCountry') {
      const selectedCountry = value;
      const filteredSpecies = species[value] || [];
      setFormData((prevData) => ({
        ...prevData,
        projectCountry: selectedCountry,
        projectSpecies: filteredSpecies.length > 0 ? { [filteredSpecies[0]]: 100 } : {}
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    formData.user_id = userID;
    e.preventDefault(); // Prevent default form submission behavior

    // if formdata species is empty, then return an error
    if (formData.projectSpecies === '' || formData.projectSpecies === {}) {
        setErrorMessage('Please select a species, current option is default.');
        return;
    }

    try {
      const response = await axios.post(`${url}/project/`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      const response_data = response.data;

      if (response.status === 200) {
        setProjectID(response_data.id);
        setParentID(response_data.parent_id);
        if (superUser === true || subscription === true && monthly_orders === true || trial === true && monthly_orders === true) {
          navigate('/dashboard');
          onClose();
        } else {
          setShowStripeApp(true);
        }
      } else {
        throw new Error('Unexpected response status: ' + response.status);
      }
    } catch (error) {
      setErrorMessage('Error while submitting, please double check your inputs or drop an email to info@artiocarbon.com.');
      console.error('Error creating project:', error);
    }
  };

  return (
    <div className="overlay">
      <div className="popup">
        {showStripeApp ? (
          <StripeApp url={url} project_id={projectID} parent_id={parentID} user_id={userID} orderType={orderType} orderPrice={orderPrice} />
        ) : (
          <>
            <h2>New Order</h2>
            {orderType && <h3>Order Type: {orderType}</h3>}
            {!orderType ? (
              <div>
                <div className="order-type-selection">
                  <button onClick={() => {
                    setOrderType('Carbon Modelling') & setPrice(299);
                    logEvent('Order', 'Carbon Modelling');
                    formData.orderType = 'Carbon Modelling';
                  }}>Carbon Modelling</button>
                  <button onClick={() => {
                    setOrderType('Economic Feasibility') & setPrice(399);
                    logEvent('Order', 'Economic Feasibility')
                    formData.orderType = 'Economic Feasibility';
                  }}>Economic Feasibility</button>
                  <button onClick={() => {
                    setOrderType('Feasibility Study');
                    logEvent('Order', 'Feasibility Study')
                    formData.orderType = 'Feasibility Study';
                  }}>Feasibility Study</button>
                </div>
                <div className="form-row submit-cancel">
                  <button type="button" className="order-type-cancel-btn" onClick={onClose}>Cancel</button>
                </div>
              </div>
            ) : orderType === 'Feasibility Study' ? (
              <div className='feasibility-popup'>
                <p>To order a feasibility study, please schedule a call with us</p>
                <a href="https://calendly.com/d/cpgh-cs6-xnt/artio-intro-economic-feasibility" target="_blank" rel="noopener noreferrer">here</a>
                <img src={FeasibilityImage} alt="Feasibility Image" className="feasibility-image"/>
                <div className="form-row submit-cancel">
                  <div className="input-data cancel-btn">
                    <button type="button" onClick={onClose}>Cancel</button>
                  </div>
                </div>
              </div>
            ) : orderType === 'Carbon Modelling' ? (
                <form className="order-form" onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="input-data">
                      <input
                          type="text"
                          name="projectName"
                          value={formData.projectName}
                          onChange={handleFormChange}
                          required
                      />
                      <div className="underline"></div>
                      <label htmlFor="projectName">Project Name</label>
                    </div>
                    <div className="input-data">
                      <select
                          name="projectType"
                          value={formData.projectType}
                          onChange={handleFormChange}
                          required
                      >
                        {projectTypes.map((projectType) => (
                            <option key={projectType} value={projectType}>
                              {projectType}
                            </option>
                        ))}
                      </select>
                      <div className="underline"></div>
                      <label htmlFor="projectType">Project Type</label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-data">
                      <input
                          type="number"
                          name="projectSize"
                          value={formData.projectSize}
                          onChange={handleFormChange}
                          required
                      />
                      <div className="underline"></div>
                      <label htmlFor="projectSize">Size</label>
                    </div>
                    <div className="input-data">
                      <select
                          name="projectCountry"
                          value={formData.projectCountry}
                          onChange={handleFormChange}
                          required
                      >
                        {countries.map((country) => (
                            <option key={country} value={country}>
                              {country}
                            </option>
                        ))}
                      </select>
                      <div className="underline"></div>
                      <label htmlFor="projectCountry">Country</label>
                    </div>
                  </div>
                  <MultiSelectForm
                      species={species}
                      formData={formData}
                      setFormData={setFormData}
                  />
                  <div className="form-row textarea">
                    <label htmlFor="description">Project Description</label>
                    <textarea
                        name="projectDescription"
                        rows="8"
                        value={formData.projectDescription}
                        onChange={handleFormChange}
                    ></textarea>
                    <div className="underline"></div>
                  </div>
                  <div className="form-row submit-cancel">
                    <div className="input-data cancel-btn">
                      <button type="button" onClick={onClose}>Cancel</button>
                    </div>
                    <div className="input-data submit-btn">
                      <button type="submit">
                        {subscription === true && monthly_orders === true || trial === true && monthly_orders === true ? 'Proceed' : `Proceed (£${orderPrice})`}
                      </button>
                    </div>
                  </div>
                  {errorMessage && <div className="error-message">{errorMessage}</div>}
                </form>
            ) : (
                <form className="order-form" onSubmit={handleSubmit}>
                  <div className="form-row">
                    <div className="input-data">
                      <input
                          type="text"
                          name="projectName"
                          value={formData.projectName}
                          onChange={handleFormChange}
                          required
                      />
                      <div className="underline"></div>
                      <label htmlFor="projectName">Project Name</label>
                    </div>
                    <div className="input-data">
                      <select
                          name="projectType"
                          value={formData.projectType}
                          onChange={handleFormChange}
                          required
                      >
                        {projectTypes.map((projectType) => (
                            <option key={projectType} value={projectType}>
                              {projectType}
                            </option>
                        ))}
                      </select>
                      <div className="underline"></div>
                      <label htmlFor="projectType">Project Type</label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-data">
                      <input
                          type="number"
                          name="projectSize"
                          value={formData.projectSize}
                          onChange={handleFormChange}
                          required
                      />
                      <div className="underline"></div>
                      <label htmlFor="projectSize">Size</label>
                    </div>
                    <div className="input-data">
                      <select
                          name="projectCountry"
                          value={formData.projectCountry}
                          onChange={handleFormChange}
                          required
                      >
                        {countries.map((country) => (
                            <option key={country} value={country}>
                              {country}
                            </option>
                        ))}
                      </select>
                      <div className="underline"></div>
                      <label htmlFor="projectCountry">Country</label>
                    </div>
                  </div>
                  <MultiSelectForm
                      species={species}
                      formData={formData}
                      setFormData={setFormData}
                  />
                  <div className="form-row">
                    <div className="input-data">
                      <select
                          name="landAcquisitionType"
                          value={formData.landAcquisitionType}
                          onChange={handleFormChange}
                          required
                      >
                        {landAcquisitionTypes.map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                        ))}
                      </select>
                      <div className="underline"></div>
                      <label htmlFor="landAcquisitionType">Land Acquisition Type</label>
                    </div>
                    <div className="input-data">
                      <select
                          name="registry"
                          value={formData.registry}
                          onChange={handleFormChange}
                          required
                      >
                        {registries.map((registry) => (
                            <option key={registry} value={registry}>
                              {registry}
                            </option>
                        ))}
                      </select>
                      <div className="underline"></div>
                      <label htmlFor="registry">Registry</label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-data">
                      <select
                          name="landAcquisitionCostFrequency"
                          value={formData.landAcquisitionCostFrequency}
                          onChange={handleFormChange}
                          required
                      >
                        {landAcquisitionCostFrequencies.map((frequency) => (
                            <option key={frequency} value={frequency}>
                              {frequency}
                            </option>
                        ))}
                      </select>
                      <div className="underline"></div>
                      <label htmlFor="landAcquisitionCostFrequency">Land Acquisition Cost Frequency</label>
                    </div>
                    <div className="input-data">
                      <input
                          type="number"
                          name="landAcquisitionCost"
                          value={formData.landAcquisitionCost}
                          onChange={handleFormChange}
                      />
                      <div className="underline"></div>
                      <label htmlFor="landAcquisitionCost">Land Acquisition Cost USD (Optional)</label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="input-data">
                      <input
                          type="number"
                          name="creditPrice"
                          value={formData.creditPrice}
                          onChange={handleFormChange}
                      ></input>
                      <div className="underline"></div>
                      <label htmlFor="creditPrice">Starting Credit Price USD (Optional)</label>
                    </div>
                    <div className="input-data">
                      <input
                          type="number"
                          name="creditPriceGrowth"
                          value={formData.creditPriceGrowth}
                          onChange={handleFormChange}
                      />
                      <div className="underline"></div>
                      <label htmlFor="creditPriceGrowth">Price Growth % (1-100) (Optional)</label>
                    </div>
                  </div>
                  <div className="form-row textarea">
                    <label htmlFor="description">Project Description</label>
                    <textarea
                        name="projectDescription"
                        rows="8"
                        value={formData.projectDescription}
                        onChange={handleFormChange}
                    ></textarea>
                    <div className="underline"></div>
                  </div>
                  <div className="form-row submit-cancel">
                    <div className="input-data cancel-btn">
                      <button type="button" onClick={onClose}>Cancel</button>
                    </div>
                    <div className="input-data submit-btn">
                      <button type="submit">
                        {subscription === true && monthly_orders === true || trial === true && monthly_orders === true ? 'Proceed' : `Proceed (£${orderPrice})`}
                      </button>
                    </div>
                  </div>
                  {errorMessage && <div className="error-message">{errorMessage}</div>}
                </form>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NewOrderPopup;
