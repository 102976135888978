import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Dashboard.css';
import NewOrderPopup from '../NewOrder/NewOrderPopup';
import ProjectCard from '../ProjectCard/ProjectCard';
import FeatureRequest from '../FeatureRequestPopup/FeatureRequestPopup';
import logo from '../logo.png';
import {logEvent} from "../../gtag";
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Dashboard() {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const token = localStorage.getItem('token');
  const url = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [fullName, setFullName] = useState('');
  const [userData, setUserData] = useState({});
  const [userCreated, setUserCreated] = useState();
  const [featureForm, setFeatureForm] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${url}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      const data = response.data;
      const first_name = data.first_name;
      const last_name = data.last_name;
      const userID = data.user_id;
      setUserCreated(data.created_at);
      const userCreatedDate = new Date(userCreated);
      setFullName(first_name + " " + last_name);
      localStorage.setItem('fullName', first_name + " " + last_name);
      try {
        const response = await axios.get(`${url}/projects/${userID}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        const data = response.data;
        if (!data) {
          setProjects([]);
        } else {
          const uniqueProjects = data.filter((project, index, self) =>
            index === self.findIndex((p) => (
              p.parent_id === project.parent_id
            ))
          );
          uniqueProjects.forEach(project => {
            const projectRuns = data.filter((run) => run.parent_id === project.parent_id);
            project.totalRuns = projectRuns.length;
            project.runsLeft = 3 - projectRuns.length;
          });
          setProjects(uniqueProjects);
          setFilteredProjects(uniqueProjects);
        }
      } catch (error) {
        console.error('Error fetching projects', error);
        navigate('/login/');
      }
      setUserData(data);
    } catch (error) {
      console.error('Error fetching user', error);
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  const handleProjectClick = (project) => {
    navigate(`/projects/${project.id}/runs`, { state: { url, projectId: project.id } });
  };

  const handleClose = () => {
    setShowForm(false);
    setFeatureForm(false);
    fetchProjects(); // Fetch projects after the form is closed
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const handleAccount = () => {
    navigate('/account');
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query === '') {
      setFilteredProjects(projects);
    } else {
      setFilteredProjects(projects.filter(project =>
        project.projectName.toLowerCase().includes(query)
      ));
    }
  };


  return (
      <div className="user-page">
        <div className="banner">
          <div className="banner-left">
            <img src={logo} alt="Artio" className="logo"/>
            <button className="banner-dashboard" onClick={() => window.location.reload()}>Dashboard</button>
            <div className="dropdown">
              <button className="dropdown-button">
                User Docs <FontAwesomeIcon icon={faCaretDown} />
              </button>
              <div className="dropdown-content">
                <a href="https://docsend.com/view/wdsthjmx8in7nhfg" target="_blank"
                   className="banner-dashboard">Methodology</a>
                <a href="https://docsend.com/view/adb8t6u8uurt2eig" target="_blank" className="banner-dashboard">User
                  Guide</a>
              </div>
            </div>
          </div>
          <div className="banner-center">
            <div className="banner-title">My Projects</div>
          </div>
          <div className="banner-right">
            <button className="banner-name" onClick={handleAccount}>{fullName}</button>
            <button className="banner-logout" onClick={handleLogout}>Logout</button>
          </div>
        </div>
        <div className="section-header">
          <div className="left-section">
            <input
                type="text"
                placeholder="Search projects..."
                value={searchQuery}
                onChange={handleSearch}
                className="search-input"
            />
            <button className="feature-btn" onClick={() => {
              setFeatureForm(true);
              logEvent("Feature Request", "Feature Request Clicked");
            }}>Feature Request
            </button>
          </div>
          <button className="new-order-btn" onClick={() => {
            setShowForm(true);
            logEvent("New Order", "New Order Clicked");
          }}>New Order
          </button>
        </div>
        {featureForm && (
            <FeatureRequest
                url={url}
                onClose={handleClose}
                userID={userData.user_id}
            />)}
        {showForm && (
            <NewOrderPopup
                url={url}
                onClose={handleClose}
                user={userData}
            />
        )}
        <div className="project-cards">
          {filteredProjects.map((project) => (
              <ProjectCard
                  key={project.id}
                  project={project}
                  cardType="parent"
                  onClick={() => handleProjectClick(project)} // Pass the whole project
              />
          ))}
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <div className="footer">
          <a href="mailto:app@artiocarbon.com?subject=Information Request&body=Hi Artio."
             target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
            Contact us via email
          </a>
        </div>
      </div>
  );
}

export default Dashboard;
